<template>
  <div>
    <p class="msg">
      <van-icon
        name="arrow-left"
        size="0.34rem"
        style="margin-left: 0.2rem"
        @click="$router.push('/msg')"
      />
      <span class="wan">完善信息</span>
      <!-- <span style="margin-left: 1.7rem" @click="$router.push('/preview')"
        >预览</span
      > -->
    </p>
    <div class="identity">
      <p class="tian">填写简历信息</p>
      <p class="buddha cy">
        <span class="ss" @click="show = true">求职状态</span>
        <input
          readonly="readonly"
          class="lian"
          v-model="statusseeking"
          @click="show = true"
          placeholder="请选择求职状态"
          style="text-align: right"
        />
      </p>
      <van-action-sheet v-model="show">
        <div class="content">
          <van-picker
            title=""
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="onCancel"
          />
        </div>
      </van-action-sheet>
      <p class="buddha cy">
        <span class="sr ss" @click="$router.push('/intention')">意向行业</span>
        <!-- <span id="rigth">餐饮</span>
          <span id="rigth">互联网</span>
          <span id="rigth">互联网</span> -->

        <span
          v-if="industry.length > 0"
          class="rigth"
          @click="$router.push('/intention')"
          >{{ industry }}</span
        >
        <input
          readonly="readonly"
          v-else
          @click="$router.push('/intention')"
          class="lian"
          type="text"
          placeholder="请选择行业"
          style="text-align: right"
        />
      </p>
      <p class="buddha">
        <span class="sr ss" @click="$router.push('/station')">求职岗位</span>
        <input
          readonly="readonly"
          v-if="job == null"
          class="lian"
          type="text"
          v-model="job"
          placeholder="请选择职业岗位"
          style="text-align: right"
          @click="$router.push('/station')"
        />
        <span v-if="job" class="rigth" @click="$router.push('/station')">{{
          job
        }}</span>
      </p>

      <!-- 选择学历 -->
    </div>
    <div>
      <p class="btn" @click="check" id="triggerBtn">下一步</p>
    </div>
  </div>
</template>

<script>
import Addressinfo from "../util/Area";
export default {
  data() {
    return {
      columns: [
        "离职-随时到岗",
        "在职-月内到岗",
        "在职-考虑机会",
        "在职-暂不考虑",
      ],

      show: false,
      statusseeking: "",
      msg: "hello",
      industry: "",
      job: "",
      // politics: "",
      // time: "",
      // address: "",

      //行业
      industry: "",
    };
  },
  created() {
    let allmsg = JSON.parse(localStorage.getItem("allmsg"));
    console.log(allmsg.jop);
    if (allmsg.industry) {
      this.industry = allmsg.industry;
      console.log(this.industry)
    }
    if (localStorage.getItem("intention2")) {
      this.industry = (localStorage.getItem("intention2"));
    }

    this.advantage = localStorage.getItem("advantage");
    // localStorage.getItem("");
    this.job = localStorage.getItem("station");
    if (!this.job && allmsg.jop) {
      this.job = allmsg.jop;
    }
    this.statusseeking = localStorage.getItem("statusseeking");
    if (!this.statusseeking && allmsg.job_status) {
      if (allmsg.job_status == 1) {
        this.statusseeking = "离职-随时到岗";
      } else if (allmsg.job_status == 2) {
        this.statusseeking = "在职-月内到岗";
      } else if (allmsg.job_status == 3) {
        this.statusseeking = "在职-考虑机会";
      } else if (allmsg.job_status == 4) {
        this.statusseeking = "在职-暂不考虑";
      } else {
        this.statusseeking = "";
      }
    }

    // this.politics = localStorage.getItem("face");
    // this.address = localStorage.getItem("area");
    // this.time = localStorage.getItem("agelimit");
    this.mailbox = localStorage.getItem("mail");
  },
  methods: {
    //确定选择求职状态
    onConfirm(value, index) {
      // this.$toast(`当前值1：${value}, 当前索引：${index}`);
      this.statusseeking = value;
      localStorage.setItem("statusseeking", this.statusseeking);
      this.show = false;
    },
    //取消选择求职状态
    onCancel() {
      this.show = false;
    },
    //保存内容进入下一步
    check() {
      if (typeof this.industry === "object") {
        this.$toast.fail("请选择行业");
        return;
      }
      if (this.job == null) {
        this.$toast.fail("请选择职业岗位");
        return;
      }
      if (this.statusseeking == null) {
        this.$toast.fail("请选择求职状态");
        return;
      }
      let id = localStorage.getItem("id");
      let statusseeking1;
      statusseeking1 =
        this.statusseeking == "离职-随时到岗"
          ? 1
          : this.statusseeking == "在职-月内到岗"
          ? 2
          : this.statusseeking == "在职-考虑机会"
          ? 3
          : this.statusseeking == "在职-暂不考虑"
          ? 4
          : "";
      // let intention1 = "";
      // intention.forEach((item) => {
      //   intention1 += item.name + "  ";
      // });
      this.$http
        .post("/firm/v1/Resume/improveInfo", {
          reqType: "resume",
          id: id,
          job_status: statusseeking1,
          industry: this.industry,
          jop: this.job,
          pageno: 2, //页码
        })
        .then((res) => {
          res = JSON.parse(res.data);
          localStorage.setItem("intention",this.industry);
          console.log(res);
          if (res.code == 0) {
            this.$router.push("/resume1");
          } else {
            this.$toast.fail(res.msg);
          }
        })
        .catch((eer) => {
          console.log(eer);
        });
    },
  },
  destroyed(){
    localStorage.removeItem("intention2");
  }
};
</script>

<style scoped>
.msg {
  height: 0.88rem;
  line-height: 0.88rem;
  line-height: 0.48rem;
  border-bottom: solid 0.01rem #eeeeee;
}
input {
  width: 5rem;
}
.ss::before {
  content: "*";
  color: #ff0000;
  font-size: 0.25rem;
  position: relative;
  margin-right: 0.1rem;
  /* top: 0.1rem; */
}
.ss{
  white-space: nowrap;
}
.msg span {
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
}
.rigth {
  float: right;
  width: 4rem;
  white-space: nowrap;
  overflow: auto;
  text-align: right;
}
.msg .xia {
  float: right;
  margin-top: 0.24rem;
  margin-right: 0.3rem;
}

.msg .wan {
  margin-right: 0.3rem;
  margin-left: 2.6rem;
}

.tian {
  font-size: 0.45rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #616b80;
  margin-top: 0.38rem;
  margin-bottom: 0.38rem;
}

.identity {
  margin: 0rem 0.32rem;
}

.identity .buddha {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 1.46rem;
  border-bottom: solid 0.01rem #eee;
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #697386;
  line-height: 1.46rem;
}

.identity .buddha .lian {
  border: none;
}

/* .sr::before {
  content: "*";
  color: #ff0000;
  font-size: 0.25rem;
  position: relative;
  margin-right: 0.1rem;
} */

.btn {
  width: 6.86rem;
  height: 0.96rem;
  background: linear-gradient(90deg, #f55613 0%, #fc9e46 100%);
  border-radius: 0.48rem;
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  line-height: 0.96rem;
  margin-top: 1.38rem;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-bottom: 0.78rem;
}

.buddha textarea {
  float: right;
  width: 3.5rem;
  height: 1rem;
  margin-top: 0.2rem;
  margin-left: 0.1rem;
  border: none;
}
</style>
